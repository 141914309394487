import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Prueba from "./Prueba";
import App from "./App";
// import Other from "./Other";

function Routeador() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Prueba />} />
        <Route path="/scene-viewer" element={<App />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  );
}

export default Routeador;

import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    enhanceWithOther(); // eslint-disable-next-line
  }, []);

  const enhanceWithOther = () => {
    console.log("ar other");
    var a = document.createElement("label");
    a.setAttribute("value", "no compatible");
    document.body.appendChild(a);
    up_logo();
  };
  //funcion para subir el boton AR de shadow_root y cambiar el logo... no se esta usando
  const up_logo = () => {
    var imagen = document.createElement("img");
    imagen.setAttribute("src", "/cellar_voyage.svg");
    imagen.setAttribute("width", "50px");
    imagen.setAttribute("heigth", "50px");
    document
      .querySelector("#reality")
      .shadowRoot.querySelector("#default-ar-button")
      .setAttribute("style", "bottom: auto;");
    document
      .querySelector("#reality")
      .shadowRoot.querySelector("#default-ar-button")
      .setAttribute("style", "top: 50px;");
    document
      .querySelector("#reality")
      .shadowRoot.querySelector("#view_x5F_in_x5F_AR_x5F_icon")

      .replaceWith(imagen);
    // no se puede
    // let a = document
    //   .querySelector("#reality")
    //   .shadowRoot.querySelector("#default-ar-button");
    // console.log(a);
    // // setTimeout(() => {
    // //   console.log("click")
    // //   a.click()
    // // }, 5000);
  };

  return (
    <div className="container-model-viewer">
      <model-viewer
        id="reality"
        src={`/models/malbec_2023.glb`}
        ios-src={`/models/malbec_2023.reality`}
        ar
        camera-controls
        alt="Malbec Moviliza"
        background-color="#000000"
        autoplay
      ></model-viewer>
    </div>
  );
}

export default App;

import React, { useLayoutEffect, useState } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";

const Prueba = () => {
  const navigate = useNavigate();
  const [isMobile] = useState({
    Android: function () {
      return /Android/i.test(navigator.userAgent);
    },
    iOS: function () {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
  });
  useLayoutEffect(() => {
    if (isMobile.iOS()) {
      if (window.navigator.userAgent.includes("Instagram")) {
        setTimeout(() => {
          navigate("/scene-viewer");
        }, 100);
      } else {
        window.onload = enhanceWithARQuickLook();
      }
    } else if (isMobile.Android()) {
      // window.onload = enhanceWithXR();
      window.onload = enhanceWithARcore();
    } else {
      console.log("entro a other");
      setTimeout(() => {
        navigate("/scene-viewer");
      }, 100);
    } // eslint-disable-next-line
  }, []);

  const enhanceWithARQuickLook = async () => {
    setTimeout(() => {
      let elem = document.querySelector("#iphone");
      elem.click();
      console.log("entro a ios");
    }, 1000);
  };
  const enhanceWithARcore = () => {
    console.log("ar core");
    const fallbackUrl = `https://malbecmoviliza.argency.com/scene-viewer`;

    const sceneViewerUrl =
      `intent://arvr.google.com/scene-viewer/1.1?file=https://malbecmoviliza.argency.com/models/malbec_2023.glb&mode=ar_only&sound=https://malbecmoviliza.argency.com/models/malbec_2023.mp3#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=` +
      fallbackUrl +
      ";end;";
    // Create a link.
    const elements = document.createElement("img");
    elements.setAttribute("src", "logo_malbec.png");
    elements.setAttribute("alt", "vino");
    elements.setAttribute("id", "loading-load-img");
    // elements.setAttribute("height", "200px");
    // elements.setAttribute("width", "310px");

    const contenedor = document.createElement("div");
    contenedor.setAttribute("id", "loading-logo");
    contenedor.appendChild(elements);
    var a = document.createElement("a");
    // const supportsAR = a.relList.supports('ar')
    // alert(supportsAR);
    a.setAttribute("id", "android");
    a.setAttribute("href", sceneViewerUrl);
    a.appendChild(contenedor);
    document.body.appendChild(a);
    document.querySelector("#android").click();
  };

  if (isMobile.iOS()) {
    return (
      <div id="loading-logo">
        <a id="iphone" href={`/models/malbec_2023.reality`} rel="ar">
          <img
            id="loading-logo-img"
            src="/logo_malbec.png"
            alt="AR"
            data-hires-status="pending"
          />
        </a>
      </div>
    );
  } else if (isMobile.Android()) {
    return (
      <div>
        {/* android: no necesito link porque lo creo directamente en la funcion enhanceWithARcore */}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Prueba;
